<style>
.app-body {
	overflow-x: unset;
}

.header-fixed .app-header {
	z-index: 1021;
}

.sidenav-sticky {
	top: 68px;
}

.sidenav-sticky .card-footer {
	padding: 2rem;
	background-color: #65a930;
	background: linear-gradient(90deg, #f4a261 0%, #e76f51 100%);
	color: white;
}

.sidenav-sticky .card-footer.success {
	background-color: #65a930;
	background: linear-gradient(90deg, #00af75 0%, #00a09c 100%);
}

.sidenav-sticky .card-footer .label {
	display: block;
	text-transform: uppercase;
	font-size: 0.7rem;
}

.sidenav-sticky .card-footer .score {
	font-size: 3rem;
}
</style>

<template>
	<div>
		<errorContainer :error="erreur" :warning="warning">
			<div>
				<!-- <h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">{{ FormMSG(29, 'Production') }}</h1> -->

				<b-row class="albert-justify-content-md-center mt-3 mb-3">
					<b-col
						:cols="$screen.width >= 1295 ? 8 : 12"
						:offset="$screen.width >= 1295 ? 2 : 0"
						class="p-5 text-left albert-bg-modal"
						style="margin-left: 0"
					>
						<b-form @submit.prevent="updatingAlbertProd">
							<b-row>
								<b-col :cols="$screen.width > 700 ? 8 : 12">
									<b-form-group
										:label="`${FormMSG(1, 'Production name')} *`"
										label-class="albert-label-form albert-fs-13"
										label-for="supplier-input-iban"
									>
										<input
											v-model="albert_prod.prodName"
											type="text"
											:placeholder="FormMSG(1, 'Production name')"
											required="required"
											class="albert-fontInputPlaceholder albert-width99per albert-formInputOval"
										/>
									</b-form-group>
								</b-col>
								<b-col :cols="$screen.width > 700 ? 4 : 12">
									<b-form-group
										:label="`${FormMSG(2, 'Period Name')} *`"
										label-class="albert-label-form albert-fs-13"
										label-for="supplier-input-iban"
									>
										<input
											v-model="albert_prod.periodName"
											type="text"
											required="required"
											:placeholder="FormMSG(24, 'E.g. S1, series 1, 2021')"
											class="albert-fontInputPlaceholder albert-width99per albert-formInputOval"
										/>
									</b-form-group>
								</b-col>
							</b-row>
							<b-row>
								<b-col :cols="$screen.width > 700 ? 4 : 12">
									<b-form-group
										:label="`${FormMSG(3, 'Production type')} *`"
										label-class="albert-label-form albert-fs-13"
										label-for="supplier-input-iban"
									>
										<select
											v-model="albert_prod.prodType"
											required="required"
											class="albert-fontInputPlaceholder albert-width99per albert-formInputOval albert-select"
										>
											<option v-for="(menu, index) in menusProdType" :key="index" :value="menu.value === 0 ? '' : menu.value">
												{{ menu.value !== 0 ? menu.text : FormMSG(25, 'Please select') }}
											</option>
										</select>
									</b-form-group>
								</b-col>
								<b-col :cols="$screen.width > 700 ? 4 : 12">
									<b-form-group
										:label="`${FormMSG(4, 'Genre')} *`"
										label-class="albert-label-form albert-fs-13"
										label-for="supplier-input-iban"
									>
										<select
											v-model="albert_prod.genre"
											required="required"
											class="albert-fontInputPlaceholder albert-width99per albert-formInputOval albert-select"
										>
											<option v-for="(menu, index) in menusGenre" :key="index" :value="menu.value === 0 ? '' : menu.value">
												{{ menu.value !== 0 ? menu.text : FormMSG(25, 'Please select') }}
											</option>
										</select>
									</b-form-group>
								</b-col>
								<b-col :cols="$screen.width > 700 ? 4 : 12">
									<b-form-group
										:label="`${FormMSG(5, 'Production Method')} *`"
										label-class="albert-label-form albert-fs-13"
										label-for="supplier-input-iban"
									>
										<select
											v-model="albert_prod.prodMethod"
											required="required"
											class="albert-fontInputPlaceholder albert-width99per albert-formInputOval albert-select"
										>
											<option v-for="(menu, index) in menusProdMethod" :key="index" :value="menu.value === 0 ? '' : menu.value">
												{{ menu.value !== 0 ? menu.text : FormMSG(25, 'Please select') }}
											</option>
										</select>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col class="albert-fontFromGroupTitle form-group-title">
									{{ FormMSG(6, 'Production dates') }}
								</b-col>
							</b-row>

							<b-row>
								<b-col :cols="$screen.width > 935 ? 3 : 6">
									<b-form-group
										:label="`${FormMSG(7, 'Pre-Production start date')} *`"
										label-class="albert-label-form albert-fs-13"
										label-for="supplier-input-iban"
									>
										<input
											v-model="albert_prod.preProdStart"
											type="date"
											required="required"
											class="albert-fontInputPlaceholder albert-width99per albert-formInputOval"
										/>
									</b-form-group>
								</b-col>
								<b-col :cols="$screen.width > 935 ? 3 : 6">
									<b-form-group
										:label="`${FormMSG(8, 'Production start date')} *`"
										label-class="albert-label-form albert-fs-13"
										label-for="supplier-input-iban"
									>
										<input
											v-model="albert_prod.prodStart"
											type="date"
											required="required"
											class="albert-fontInputPlaceholder albert-width99per albert-formInputOval"
										/>
									</b-form-group>
								</b-col>
								<b-col :cols="$screen.width > 935 ? 3 : 6">
									<b-form-group
										:label="FormMSG(9, 'Post-Production start date')"
										label-class="albert-label-form albert-fs-13"
										label-for="supplier-input-iban"
									>
										<input
											v-model="albert_prod.postProdStart"
											type="date"
											class="albert-fontInputPlaceholder albert-width99per albert-formInputOval"
										/>
									</b-form-group>
								</b-col>
								<b-col :cols="$screen.width > 935 ? 3 : 6">
									<b-form-group
										:label="`${FormMSG(10, 'Post-Production end date')} *`"
										label-class="albert-label-form albert-fs-13"
										label-for="supplier-input-iban"
									>
										<input
											v-model="albert_prod.postProdEnd"
											type="date"
											required="required"
											class="albert-fontInputPlaceholder albert-width99per albert-formInputOval"
										/>
									</b-form-group>
								</b-col>
								<b-col :cols="$screen.width > 935 ? 3 : 6">
									<b-form-group
										:label="FormMSG(11, 'TX / Release Date')"
										label-class="albert-label-form albert-fs-13"
										label-for="supplier-input-iban"
									>
										<input
											v-model="albert_prod.txReleaseDate"
											type="date"
											class="albert-fontInputPlaceholder albert-width99per albert-formInputOval"
										/>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col class="albert-fontFromGroupTitle">
									{{ FormMSG(12, 'Additional Details') }}
								</b-col>
							</b-row>

							<b-row class="mb-2">
								<b-col :cols="$screen.width > 700 ? 6 : 12" class="p-1">
									<div
										class="albert-fs-13 font-weight-bold"
										v-html="FormMSG(13, 'How many episodes/features are you recording?<br> (For a single feature, enter ‘1’) *')"
									/>
								</b-col>
								<b-col :cols="$screen.width > 700 ? 3 : 12">
									<input
										v-model="albert_prod.numEpisode"
										type="text"
										required="required"
										:placeholder="FormMSG(26, 'E.g: 1')"
										class="albert-fontInputPlaceholder albert-width99per albert-formInputOval"
									/>
								</b-col>
							</b-row>
							<b-row class="mb-2">
								<b-col :cols="$screen.width > 700 ? 6 : 12" class="p-3">
									<div class="albert-fs-13 font-weight-bold">
										{{ FormMSG(14, 'Running time of each episode/feature (mins)?') }}
										*
									</div>
								</b-col>
								<b-col :cols="$screen.width > 700 ? 3 : 12">
									<input
										v-model="albert_prod.runningTime"
										type="text"
										required="required"
										:placeholder="FormMSG(27, 'E.g: 30')"
										class="albert-fontInputPlaceholder albert-width99per albert-formInputOval"
									/>
								</b-col>
							</b-row>
							<b-row class="mb-2">
								<b-col :cols="$screen.width > 700 ? 6 : 12" class="p-3">
									<div class="albert-fs-13 font-weight-bold">
										{{ FormMSG(15, 'What is the budget for this production?') }}
									</div>
								</b-col>
								<b-col :cols="$screen.width > 700 ? 3 : 6">
									<input
										v-model="albert_prod.prodBudget"
										type="text"
										:placeholder="FormMSG(28, 'E.g: 1 000 000')"
										class="albert-fontInputPlaceholder albert-width99per albert-formInputOval"
									/>
								</b-col>
								<b-col :cols="$screen.width > 700 ? 3 : 6">
									<select required="required" class="albert-fontInputPlaceholder albert-width99per albert-formInputOval albert-select">
										<option v-for="(money, index) in moneys" :key="index" :value="money" :selected="money === 'GBP' ? 'selected' : ''">
											{{ money }}
										</option>
									</select>
								</b-col>
							</b-row>

							<b-row>
								<b-col class="albert-fontFromGroupTitle">
									{{ FormMSG(16, 'Production Ownership') }}
								</b-col>
							</b-row>

							<b-row>
								<b-col :cols="$screen.width > 700 ? 6 : 12">
									<b-form-group :label="`${FormMSG(17, 'Company name')} *`" label-class="albert-label-form albert-fs-13">
										<input
											v-model="albert_prod.prodCompany"
											required="required"
											type="text"
											:placeholder="FormMSG(17, 'Company name')"
											class="albert-fontInputPlaceholder albert-width99per albert-formInputOval"
										/>
									</b-form-group>
								</b-col>
								<b-col :cols="$screen.width > 700 ? 6 : 12">
									<b-form-group :label="`${FormMSG(18, 'Broadcaster / Funder')} *`" label-class="albert-label-form albert-fs-13">
										<input
											v-model="albert_prod.funder"
											required="required"
											type="text"
											:placeholder="FormMSG(18, 'Broadcaster / Funder')"
											class="albert-fontInputPlaceholder albert-width99per albert-formInputOval"
										/>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row>
								<b-col class="albert-fontFromGroupTitle">
									{{ FormMSG(19, 'Associated Users') }}
								</b-col>
							</b-row>

							<b-row>
								<b-col :cols="$screen.width > 700 ? 6 : 12">
									<InputSelect
										:label="FormMSG(20, 'User')"
										:placeholder="FormMSG(20, 'User')"
										:init-data="albert_prod.user"
										@user:selected="updateUser"
									/>
								</b-col>
								<b-col :cols="$screen.width > 700 ? 6 : 12">
									<b-form-group
										:label="`${FormMSG(21, 'Reviewer')} *`"
										label-class="albert-label-form albert-fs-13"
										label-for="supplier-input-iban"
									>
										<input
											v-model="albert_prod.reviewer"
											type="text"
											required
											:placeholder="FormMSG(21, 'Reviewer')"
											class="albert-fontInputPlaceholder albert-width99per albert-formInputOval"
										/>
									</b-form-group>
								</b-col>
							</b-row>

							<b-row class="mt-5">
								<b-col :cols="$screen.width > 700 ? 6 : 12" :class="{ 'text-center': $screen.width <= 700 }">
									<button type="submit" class="albert-modalBtn">
										<span v-if="!form_busy">{{ FormMSG(22, 'SAVE PRODUCTION') }} <i class="fa fa-plus" /></span>
										<span v-else><b-spinner label="Spinning" /> {{ FormMSG(202, 'UPDATE IN PROGRESS') }}</span>
									</button>
								</b-col>
								<b-col
									:cols="$screen.width > 700 ? 6 : 12"
									class="form-group text-right albert-fontSmallHeader albert-verticalMarginAuto"
									:class="{ 'text-center mt-3': $screen.width <= 700 }"
								>
									<a href="#" @click.prevent="clearAll">
										<i class="fa fa-times albert-bgCol" />
										{{ FormMSG(23, 'CLEAR') }}
									</a>
								</b-col>
							</b-row>
						</b-form>
					</b-col>
				</b-row>
			</div>
		</errorContainer>
	</div>
</template>

<script>
import InputSelect from '@/components/albert/albertprod/InputSelect';
import languageMessages from '@/mixins/languageMessages';
import { albert_money } from '@/components/albert/albertprod/albert-moneys';
import { getAlbertProd, updateAlbertProd } from '@/cruds/albert.crud';
import { omit } from '@/shared/utils';

const albertProdFields = {
	id: 0,
	prodName: '',
	periodName: '',
	prodType: '',
	genre: '',
	prodMethod: '',
	preProdStart: '',
	prodStart: '',
	postProdStart: '',
	postProdEnd: '',
	txReleaseDate: '',
	numEpisode: '',
	runningTime: '',
	prodBudget: '',
	prodCompany: '',
	funder: '',
	userId: 0,
	reviewer: '',
	user: {}
};

export default {
	name: 'AlbertProd',

	components: {
		InputSelect
	},
	mixins: [languageMessages],

	data: () => {
		return {
			erreur: {},
			warning: '',
			form_busy: false,

			moneys: albert_money,
			albert_prod: Object.assign({}, albertProdFields)
		};
	},

	computed: {
		menusProdType() {
			return this.FormMenu(1300);
		},
		menusGenre() {
			return this.FormMenu(1301);
		},
		menusProdMethod() {
			return this.FormMenu(1302);
		}
	},

	async mounted() {
		await this.makeAlbertProd();
	},

	methods: {
		/**
		 * FOR GET ALBERT PROD
		 */
		async makeAlbertProd() {
			let albert_prod = await getAlbertProd();
			albert_prod.preProdStart = this.changeDateToString(albert_prod.preProdStart);
			albert_prod.prodStart = this.changeDateToString(albert_prod.prodStart);
			albert_prod.postProdStart = albert_prod.postProdStart !== '' ? this.changeDateToString(albert_prod.postProdStart) : '';
			albert_prod.postProdEnd = this.changeDateToString(albert_prod.postProdEnd);
			albert_prod.txReleaseDate = albert_prod.txReleaseDate !== '' ? this.changeDateToString(albert_prod.txReleaseDate) : '';

			this.albert_prod = albert_prod;
		},
		async updatingAlbertProd() {
			this.form_busy = true;
			let alberProdInput = omit(['user', 'id'], this.albert_prod);
			alberProdInput.prodBudget = parseInt(alberProdInput.prodBudget);
			alberProdInput.runningTime = parseInt(alberProdInput.runningTime);
			alberProdInput.userId = parseInt(alberProdInput.userId);
			alberProdInput.numEpisode = parseInt(alberProdInput.numEpisode);
			alberProdInput.preProdStart = new Date(alberProdInput.preProdStart);
			alberProdInput.prodStart = new Date(alberProdInput.prodStart);
			alberProdInput.postProdStart = new Date(alberProdInput.postProdStart);
			alberProdInput.postProdEnd = new Date(alberProdInput.postProdEnd);
			alberProdInput.txReleaseDate = new Date(alberProdInput.txReleaseDate);

			await updateAlbertProd(alberProdInput);
			this.form_busy = false;
			this.$toast({
				message: this.FormMSG(200, 'Update successfull'),
				title: this.FormMSG(201, 'Success'),
				variant: 'success'
			});
		},
		changeDateToString(date) {
			const d = new Date(date);
			return d.getFullYear() + '-' + this.checkDateOrMonth(d.getMonth()) + '-' + this.checkDateOrMonth(d.getDate());
		},
		checkDateOrMonth(data) {
			const data_to_int = parseInt(data);
			if (data < 10) {
				return '0' + data_to_int;
			}
			return '' + data_to_int;
		},
		updateUser(payload) {
			if (payload.user) {
				this.albert_prod.userId = payload.user.id;
				this.albert_prod.user.name = payload.user.name;
				this.albert_prod.user.firstName = payload.user.firstName;
			} else {
				this.albert_prod.userId = 0;
				this.albert_prod.user.name = '';
				this.albert_prod.user.firstName = '';
			}
		},
		clearAll() {
			this.albert_prod = Object.assign({}, albertProdFields);
			this.albert_prod.user = {};
		}
	}
};
</script>
